// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WantedWarrant-container{
  position: absolute;
  height:calc(100vh - 40vh);
  width:100%;
  background-color: #342E40;

}
.WantedWarrant{
  width:100%;
  height:70%;
}
.wanted{
  max-width: 100%;
  height: 90%;
  max-height: 100%;
  border-radius: 1.3337rem;
}
.passed-container{
  width:100%;
  height:45%;
  position: absolute;
  transform: rotate(15deg);
  top:15%;
}
.d-none{
  display: none;
}
.passed{
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: none;
}
.passedIn{
  display: block;
  animation: passIn 1s ease forwards;
}
@keyframes passIn{
  0%{
    transform: scale(1.2);
  }
  100%{
    transform: scale(1);
  }
}

.eventCompelete{
  color:white;
  font-size: 1.5rem;
  font-weight: 600;
}
.compeleteIn{
  animation: compeleteDisplayAnimation 0.5s ease forwards;
}

@keyframes compeleteDisplayAnimation{
  0%{
    margin-top: 1rem;
    opacity: 0;
  }
  100%{
    margin-top: 0rem;
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/component/wantedWarrant/WantedWarrant.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,UAAU;EACV,yBAAyB;;AAE3B;AACA;EACE,UAAU;EACV,UAAU;AACZ;AACA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,wBAAwB;AAC1B;AACA;EACE,UAAU;EACV,UAAU;EACV,kBAAkB;EAClB,wBAAwB;EACxB,OAAO;AACT;AACA;EACE,aAAa;AACf;AACA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,cAAc;EACd,kCAAkC;AACpC;AACA;EACE;IACE,qBAAqB;EACvB;EACA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,uDAAuD;AACzD;;AAEA;EACE;IACE,gBAAgB;IAChB,UAAU;EACZ;EACA;IACE,gBAAgB;IAChB,UAAU;EACZ;AACF","sourcesContent":[".WantedWarrant-container{\n  position: absolute;\n  height:calc(100vh - 40vh);\n  width:100%;\n  background-color: #342E40;\n\n}\n.WantedWarrant{\n  width:100%;\n  height:70%;\n}\n.wanted{\n  max-width: 100%;\n  height: 90%;\n  max-height: 100%;\n  border-radius: 1.3337rem;\n}\n.passed-container{\n  width:100%;\n  height:45%;\n  position: absolute;\n  transform: rotate(15deg);\n  top:15%;\n}\n.d-none{\n  display: none;\n}\n.passed{\n  max-width: 100%;\n  height: auto;\n  max-height: 100%;\n  display: none;\n}\n.passedIn{\n  display: block;\n  animation: passIn 1s ease forwards;\n}\n@keyframes passIn{\n  0%{\n    transform: scale(1.2);\n  }\n  100%{\n    transform: scale(1);\n  }\n}\n\n.eventCompelete{\n  color:white;\n  font-size: 1.5rem;\n  font-weight: 600;\n}\n.compeleteIn{\n  animation: compeleteDisplayAnimation 0.5s ease forwards;\n}\n\n@keyframes compeleteDisplayAnimation{\n  0%{\n    margin-top: 1rem;\n    opacity: 0;\n  }\n  100%{\n    margin-top: 0rem;\n    opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
