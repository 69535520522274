// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainPageContainer{
  height:100%;
  position: relative;
  background-color: #2F2D2C;
}
.completeHint{
  position:relative;
  z-index: 1;
  height: 40%;
  background-color: rgba(00, 00, 00, 85%);
  display: flex;
}
.raccoon{
  height: calc(100svh - 40% - 35%);
  position:relative;
  z-index: 2;
}
.cardContainer{
  position:relative;
  z-index: 3;
  background-color: #9C8A76;
  border-radius: 2rem;
  height:50%;
  /* margin-top:calc(100vh - 23rem - 14rem); */
}
.card-hint{
  color:#FBE5C5;
  font-size: 1.25rem;
}
.mainPage-wanted{
  height:calc(100vh - 40%);
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/pages/mainPage/MainPage.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,yBAAyB;AAC3B;AACA;EACE,iBAAiB;EACjB,UAAU;EACV,WAAW;EACX,uCAAuC;EACvC,aAAa;AACf;AACA;EACE,gCAAgC;EAChC,iBAAiB;EACjB,UAAU;AACZ;AACA;EACE,iBAAiB;EACjB,UAAU;EACV,yBAAyB;EACzB,mBAAmB;EACnB,UAAU;EACV,4CAA4C;AAC9C;AACA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,wBAAwB;EACxB,kBAAkB;AACpB","sourcesContent":[".mainPageContainer{\n  height:100%;\n  position: relative;\n  background-color: #2F2D2C;\n}\n.completeHint{\n  position:relative;\n  z-index: 1;\n  height: 40%;\n  background-color: rgba(00, 00, 00, 85%);\n  display: flex;\n}\n.raccoon{\n  height: calc(100svh - 40% - 35%);\n  position:relative;\n  z-index: 2;\n}\n.cardContainer{\n  position:relative;\n  z-index: 3;\n  background-color: #9C8A76;\n  border-radius: 2rem;\n  height:50%;\n  /* margin-top:calc(100vh - 23rem - 14rem); */\n}\n.card-hint{\n  color:#FBE5C5;\n  font-size: 1.25rem;\n}\n.mainPage-wanted{\n  height:calc(100vh - 40%);\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
