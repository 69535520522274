// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.raccoon-container{
  position:relative;
  height: 100%;
  width:100%;
  background-color: #2F2D2C;
  /* overflow: hidden; */
}
.raccoon-container > .raccoon{
  position:absolute;
  top:-1%;
  left:-4svw;
  height: 100%;
  width:100%;
}
.raccoon > img{
  width:120%;
  height:auto;
}
.pipe{
  position:absolute;
  top:5rem;
  left:0px;
  height: 100%;
  width:100%;
}
.pipe > img{
  width:100%;
  height:auto;
}
.smoke{
  position:absolute;
  top:-4rem;
  left:0px;
  height: 100%;
  width:100%;
}
.smoke > img{
  width:100%;
  height:auto;
}

.raccoon-text{
  color: white;
  font-size: 1rem;
  font-weight: 600;
  white-space: break-word;
  width:-moz-fit-content;
  width:fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.raccoon-text-container{
  
  position: relative;
  top:-1rem;
  right:-57.5%;
  width:6.5rem;
  height:5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}`, "",{"version":3,"sources":["webpack://./src/component/raccoon/Raccoon.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,UAAU;EACV,yBAAyB;EACzB,sBAAsB;AACxB;AACA;EACE,iBAAiB;EACjB,OAAO;EACP,UAAU;EACV,YAAY;EACZ,UAAU;AACZ;AACA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,QAAQ;EACR,QAAQ;EACR,YAAY;EACZ,UAAU;AACZ;AACA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,SAAS;EACT,QAAQ;EACR,YAAY;EACZ,UAAU;AACZ;AACA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,sBAAiB;EAAjB,iBAAiB;EACjB,wBAAmB;EAAnB,mBAAmB;AACrB;AACA;;EAEE,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,qBAAqB;AACvB","sourcesContent":[".raccoon-container{\n  position:relative;\n  height: 100%;\n  width:100%;\n  background-color: #2F2D2C;\n  /* overflow: hidden; */\n}\n.raccoon-container > .raccoon{\n  position:absolute;\n  top:-1%;\n  left:-4svw;\n  height: 100%;\n  width:100%;\n}\n.raccoon > img{\n  width:120%;\n  height:auto;\n}\n.pipe{\n  position:absolute;\n  top:5rem;\n  left:0px;\n  height: 100%;\n  width:100%;\n}\n.pipe > img{\n  width:100%;\n  height:auto;\n}\n.smoke{\n  position:absolute;\n  top:-4rem;\n  left:0px;\n  height: 100%;\n  width:100%;\n}\n.smoke > img{\n  width:100%;\n  height:auto;\n}\n\n.raccoon-text{\n  color: white;\n  font-size: 1rem;\n  font-weight: 600;\n  white-space: break-word;\n  width:fit-content;\n  height: fit-content;\n}\n.raccoon-text-container{\n  \n  position: relative;\n  top:-1rem;\n  right:-57.5%;\n  width:6.5rem;\n  height:5rem;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
