// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,body{
  width: 100svw;
  height: 100svh;
  margin: 0px;
  padding: 0px;
}
body{
  min-height: 100svh;
}
div{
  /* border:1px solid black; */
  border:none;
}
.root{
  height: 100svh;
}
.App{
  height: 100svh;
  margin: 0;
  background-color: black;
}
.curtain-container{
  position: absolute;
  height:100%;
  width:100%;
  z-index:3;
}
.question-container{
  z-index: 1;
  height:100%;
  width: 100%;
  background-color: #F2E5D2;
  /* padding: 0.5rem 0; */
  position:absolute;
  opacity: 0;
}
.mainPage{
  z-index: 2;
  height:100%;
  width: 100%;
  overflow: hidden;
  position:absolute;
}
.open{
  animation: openPage 1s ease forwards;
}
.close{
  animation: closePage 1s ease forwards;
}
@keyframes openPage{
  0%{opacity: 0;z-index: 1;}
  50%{opacity: 0;z-index: 1;}
  100%{opacity: 1;z-index: 2;}
}
@keyframes closePage{
  0%{opacity: 1;z-index: 2;}
  25%{opacity: 0;z-index: 1;}
  100%{opacity: 0;z-index: 1;}
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,WAAW;EACX,YAAY;AACd;AACA;EACE,kBAAkB;AACpB;AACA;EACE,4BAA4B;EAC5B,WAAW;AACb;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;EACd,SAAS;EACT,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,SAAS;AACX;AACA;EACE,UAAU;EACV,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,uBAAuB;EACvB,iBAAiB;EACjB,UAAU;AACZ;AACA;EACE,UAAU;EACV,WAAW;EACX,WAAW;EACX,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,oCAAoC;AACtC;AACA;EACE,qCAAqC;AACvC;AACA;EACE,GAAG,UAAU,CAAC,UAAU,CAAC;EACzB,IAAI,UAAU,CAAC,UAAU,CAAC;EAC1B,KAAK,UAAU,CAAC,UAAU,CAAC;AAC7B;AACA;EACE,GAAG,UAAU,CAAC,UAAU,CAAC;EACzB,IAAI,UAAU,CAAC,UAAU,CAAC;EAC1B,KAAK,UAAU,CAAC,UAAU,CAAC;AAC7B","sourcesContent":["html,body{\n  width: 100svw;\n  height: 100svh;\n  margin: 0px;\n  padding: 0px;\n}\nbody{\n  min-height: 100svh;\n}\ndiv{\n  /* border:1px solid black; */\n  border:none;\n}\n.root{\n  height: 100svh;\n}\n.App{\n  height: 100svh;\n  margin: 0;\n  background-color: black;\n}\n.curtain-container{\n  position: absolute;\n  height:100%;\n  width:100%;\n  z-index:3;\n}\n.question-container{\n  z-index: 1;\n  height:100%;\n  width: 100%;\n  background-color: #F2E5D2;\n  /* padding: 0.5rem 0; */\n  position:absolute;\n  opacity: 0;\n}\n.mainPage{\n  z-index: 2;\n  height:100%;\n  width: 100%;\n  overflow: hidden;\n  position:absolute;\n}\n.open{\n  animation: openPage 1s ease forwards;\n}\n.close{\n  animation: closePage 1s ease forwards;\n}\n@keyframes openPage{\n  0%{opacity: 0;z-index: 1;}\n  50%{opacity: 0;z-index: 1;}\n  100%{opacity: 1;z-index: 2;}\n}\n@keyframes closePage{\n  0%{opacity: 1;z-index: 2;}\n  25%{opacity: 0;z-index: 1;}\n  100%{opacity: 0;z-index: 1;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
