// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answerButton{
  border-radius: 2.666667rem;
  background-color: #A4845F !important;
  color: #FBE5C5 !important;
}
.wrong{
  border: 2px solid #351616 !important;
  background-color: #772A19 !important;
}
.trueAns{
  border: 2px solid #304829 !important;
  background-color: #1C6724 !important;
}
.btn-margin{
  margin:0.7rem 0;
}
.answer-btn{
  border:2px solid #A4845F !important;
}`, "",{"version":3,"sources":["webpack://./src/component/answer/Answer.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,oCAAoC;EACpC,yBAAyB;AAC3B;AACA;EACE,oCAAoC;EACpC,oCAAoC;AACtC;AACA;EACE,oCAAoC;EACpC,oCAAoC;AACtC;AACA;EACE,eAAe;AACjB;AACA;EACE,mCAAmC;AACrC","sourcesContent":[".answerButton{\n  border-radius: 2.666667rem;\n  background-color: #A4845F !important;\n  color: #FBE5C5 !important;\n}\n.wrong{\n  border: 2px solid #351616 !important;\n  background-color: #772A19 !important;\n}\n.trueAns{\n  border: 2px solid #304829 !important;\n  background-color: #1C6724 !important;\n}\n.btn-margin{\n  margin:0.7rem 0;\n}\n.answer-btn{\n  border:2px solid #A4845F !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
