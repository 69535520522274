// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.curtain{
  width:100%;
  height:100%;
  position: relative;
}
.rope{
  position:absolute;
  height:800px;
  width:145px;
  top:-30%;
  left: 0.5rem;
}
.titleImage{
  position:absolute;
}
.title{
  color: white;
  font-size: 1.4rem;
  position:absolute;
  bottom: 5rem;
  width:100%;
  display: flex;
  justify-content: center;
}
.curtain-container.pull{
  animation: curtainUp 1s ease 0.5s forwards;
}
.rope.pull{
  animation:ropePull 1s ease forwards;
}
@keyframes ropePull{
  0%{
    top:-30%;
  }
  50%{
    top:-10%;
  }
  100%{
    top:-30%;
  }
}
@keyframes curtainUp{
  0%{top:0svh}
  100%{top:-100svh}
}`, "",{"version":3,"sources":["webpack://./src/pages/curtain/Curtain.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,YAAY;EACZ,WAAW;EACX,QAAQ;EACR,YAAY;AACd;AACA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;EACZ,UAAU;EACV,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,0CAA0C;AAC5C;AACA;EACE,mCAAmC;AACrC;AACA;EACE;IACE,QAAQ;EACV;EACA;IACE,QAAQ;EACV;EACA;IACE,QAAQ;EACV;AACF;AACA;EACE,GAAG,QAAQ;EACX,KAAK,WAAW;AAClB","sourcesContent":[".curtain{\n  width:100%;\n  height:100%;\n  position: relative;\n}\n.rope{\n  position:absolute;\n  height:800px;\n  width:145px;\n  top:-30%;\n  left: 0.5rem;\n}\n.titleImage{\n  position:absolute;\n}\n.title{\n  color: white;\n  font-size: 1.4rem;\n  position:absolute;\n  bottom: 5rem;\n  width:100%;\n  display: flex;\n  justify-content: center;\n}\n.curtain-container.pull{\n  animation: curtainUp 1s ease 0.5s forwards;\n}\n.rope.pull{\n  animation:ropePull 1s ease forwards;\n}\n@keyframes ropePull{\n  0%{\n    top:-30%;\n  }\n  50%{\n    top:-10%;\n  }\n  100%{\n    top:-30%;\n  }\n}\n@keyframes curtainUp{\n  0%{top:0svh}\n  100%{top:-100svh}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
