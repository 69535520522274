// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clue{
  height:100%;
  width: 100%;
}

.board{
  height:80%;
  background-color: #796F60;
  border: 0.5rem solid #362914;
  border-radius: 2.66667rem;
}
.board > div > .board-content{
  color:white;
  font-size: 1.75rem;
  font-weight: 700;
}
.under-board{
  background-color: #695747;
  color:#695747;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/component/clue/Clue.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;AACb;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,4BAA4B;EAC5B,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,aAAa;EACb,UAAU;AACZ","sourcesContent":[".clue{\n  height:100%;\n  width: 100%;\n}\n\n.board{\n  height:80%;\n  background-color: #796F60;\n  border: 0.5rem solid #362914;\n  border-radius: 2.66667rem;\n}\n.board > div > .board-content{\n  color:white;\n  font-size: 1.75rem;\n  font-weight: 700;\n}\n.under-board{\n  background-color: #695747;\n  color:#695747;\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
